<template>
  <div>
    <!-- <div v-show="current === 1">
      <el-form-item label="商标注册证" required>
        <file-upload :key="1" @success="onUploadSuccess($event, 'item_registration_certificate')" @error="onUploadError" />
      </el-form-item>
      <el-form-item :key="2" label="商标授权书" required>
        <file-upload @success="onUploadSuccess($event, 'item_authorization')" @error="onUploadError" />
      </el-form-item>
      <el-form-item :key="3" label="商标转让证明" required>
        <file-upload @success="onUploadSuccess($event, 'trademark_transfer_certificate')" @error="onUploadError" />
      </el-form-item>
    </div>
    <div v-show="current === 2">
      <el-form-item :key="4" label="作品登记证书" required>
        <file-upload @success="onUploadSuccess($event, 'work_registration_certificate')" @error="onUploadError" />
      </el-form-item>
      <el-form-item :key="5" label="商品原图" required>
        <file-upload @success="onUploadSuccess($event, 'item_original_picture')" @error="onUploadError" />
      </el-form-item>
      <el-form-item :key="6" label="商品花絮图" required>
        <file-upload @success="onUploadSuccess($event, 'item_sidelights')" @error="onUploadError" />
      </el-form-item>
    </div>
    <div v-show="current === 3">
      <el-form-item :key="7" label="专利证书" required>
        <file-upload @success="onUploadSuccess($event, 'patent_certificate')" @error="onUploadError" />
      </el-form-item>
      <el-form-item :key="8" label="专利权利要求与说明书" required>
        <file-upload @success="onUploadSuccess($event, 'patent_specification')" @error="onUploadError" />
      </el-form-item>
      <el-form-item :key="9" label="专利缴费证明" required>
        <file-upload @success="onUploadSuccess($event, 'patent_payment_certificate')" @error="onUploadError" />
      </el-form-item>
    </div>
    <div v-show="current === 4">
      <el-form-item :key="10" label="营业执照证明" required>
        <file-upload @success="onUploadSuccess($event, 'business_license_certificate')" @error="onUploadError" />
      </el-form-item>
    </div> -->
    <el-form-item
      v-for="item in items"
      v-show="item.showType === current"
      :key="item.key"
      :label="item.label"
      required
    >
      <file-upload 
        :key="item.key"
        :example="item.example"
        @success="onUploadSuccess($event, item.key)" 
        @error="onUploadError" 
        @delete="onUploadDelete($event, item.key)"
      />
    </el-form-item>
  </div>
</template>

<script>
  import FileUpload from '@/components/FileUpload';

  const paramsKeys = {
    1: 'trademarkParams',
    2: 'copyrightParams',
    3: 'patentParams',
    4: 'othersParams',
  }

  export default {
    components: {
      FileUpload,
    },
    props: {
      current: {
        type: Number,
        required: true,
      },
      items: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
        trademarkParams: {
          item_registration_certificate: '',
          item_authorization: '',
          trademark_transfer_certificate: ''
        },
        copyrightParams: {
          work_registration_certificate: '',
          item_original_picture: '',
          item_sidelights: '',
        },
        patentParams: {
          patent_certificate: '',
          patent_specification: '',
          patent_payment_certificate: '',
        },
        othersParams: {
          business_license_certificate: ''
        }
      }
    },

    computed: {
      uploadParams() {
        return paramsKeys[this.current];
      }
    },

    watch: {
      current(newVal) {
        console.log('watch', newVal)
        this.$emit('change', this[this.uploadParams]);
      }
    },
    methods: {
      onUploadSuccess(src, key) {
        this[this.uploadParams][key] = src;
        console.log(this[this.uploadParams]);
        this.$emit('change', this[this.uploadParams]);
      },

      onUploadError(e) {
        console.log(e);
      },

      onUploadDelete(e, key) {
        if (!e) {
          this[this.uploadParams][key] = ''
        }
      }
    }
  }
</script>

