<template>
  <div class="complaint-wrapper with-container">
    <div class="step-box">
      <div class="step-item">1.投诉并举证</div>
      <div :class="step >= 2 ? 'step-item active' : 'step-item'">2.确认信息</div>
      <div :class="step >= 3 ? 'step-item active' : 'step-item'">3.成功提交</div>
    </div>
    <div v-if="step === 1 || step === 2" class="explain">
      <h3>投诉说明:</h3>
      <ul>
        <li>1. 魔筷基于《中华人民共和国商标法》、《中华人民共和国著作权法》、《中华人民共和国专利法》、《中华人民共和国电子商务法》等法律法规，重视和保护每一位知识产权权利人的合法权益。</li>
        <li>2. 若你是知识产权权利所有人或授权人，请按照以下要求进行填写并提供相关证明。</li>
        <li>3. 魔筷将在收到投诉材料后的3个工作日内给予反馈，你可以在魔筷官网底部-知识产权-投诉记录中查看进度，请耐心等待。</li>
      </ul>
    </div>
    <div v-show="step === 1" class="form">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="200px"
      >
        <div class="form-area upload">
          <div class="form-title">投诉内容选择：</div>
          <el-form-item label="投诉类型" prop="complaint_type">
            <el-radio-group v-model="form.complaint_type" @change="onTypeChange">
              <el-radio 
                v-for="item in radioData"
                :key="item.id"
                :label="item.id"
                class="radio-block"
              >
                {{ item.text }}
              </el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <div class="form-area center">
          <div class="form-title mb-10">投诉举证：</div>
          <div class="tips">请保证上传清晰有效且完整的相关证书彩色扫描件，或复印件并加盖组织机构公章的彩色图片。</div>
          <upload-items :current="form.complaint_type" :items="items"  @change="onUploadsChange" />
        </div>
        <div class="form-area">
          <div class="form-title">投诉人信息：</div>
          <el-form-item label="投诉主体" prop="subject" required>
            <el-radio-group v-model="form.subject" @change="onSubjectChange">
              <el-radio v-for="item in roleRadioData" :key="item.id" :label="item.id">{{item.text}}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="form.subject === 1" label="企业名称" prop="business_name">
            <el-input v-model="form.business_name" :maxlength="32" class="normal-input" placeholder="请输入企业名称"></el-input>
          </el-form-item>
          <el-form-item ref="nameFormItem" label="联系人姓名" prop="contacts_name">
            <el-input v-model="form.contacts_name" class="normal-input" placeholder="请输入联系人姓名"></el-input>
          </el-form-item>
          <el-form-item ref="mobileFormItem" label="联系人电话" prop="contacts_mobile">
            <el-input v-model="form.contacts_mobile" class="normal-input" placeholder="请输入联系人电话"></el-input>
          </el-form-item>
          <el-form-item ref="emailFormItem" label="邮箱" prop="email">
            <el-input v-model="form.email" class="normal-input" placeholder="请输入邮箱"></el-input>
          </el-form-item>
          <el-form-item label="地址" required>
            <v-distpicker
              :placeholders="placeholders"
              :province="form.province"
              :city="form.city"
              :area="form.area"
              @selected="onAreaSelected"
            ></v-distpicker>
            <el-input v-model="form.detail" type="textarea" class="address-textarea" placeholder="请输入具体地址"></el-input>
          </el-form-item>
          <el-form-item ref="frontFormItem" label="身份证照片" prop="id_card_front" required>
            <file-upload ref="frontUpload" tips="请上传身份证正面照片" @success="onUploadSuccess($event, 'id_card_front')" @error="onUploadError" @delete="onUploadDelete($event, 'id_card_front')" />
          </el-form-item>
          <el-form-item ref="reverseFormItem" label="身份证照片" prop="id_card_reverse" required>
            <file-upload ref="reverseUpload" tips="请上传身份证反面照片" @success="onUploadSuccess($event, 'id_card_reverse')" @error="onUploadError" @delete="onUploadDelete($event, 'id_card_reverse')" />
          </el-form-item>
        </div>
      </el-form>
    </div>

    <div v-if="step === 2" class="form confirm-info">
      <el-form label-width="200px">
        <div class="form-area">
          <div class="form-title">投诉内容选择：</div>
          <el-form-item label="投诉类型">
            {{ complainText }}
          </el-form-item>
        </div>
        <div class="form-area">
          <div class="form-title">投诉举证：</div>
          <el-form-item 
            v-for="item in currentItems"
            :key="item.key"
            :label="item.label"
          >
            <!-- <div class="img" :style="`background-image:url(${uploadParams[item.key]});`"></div> -->
            <viewer :images="[uploadParams[item.key]]">
              <template slot-scope="scope">
                <img v-for="(src, i) in scope.images" :key="i" class="img" :src="src" alt="">
              </template>
            </viewer>
          </el-form-item>
        </div>
        <div class="form-area">
          <div class="form-title">投诉人信息：</div>
          <el-form-item label="投诉主体">
            {{ currentRole }}
          </el-form-item>
          <el-form-item v-if="form.subject === 1" label="企业名称">
            {{ form.business_name }}
          </el-form-item>
          <el-form-item label="联系人姓名">
            {{ form.contacts_name }}
          </el-form-item>
          <el-form-item label="联系人电话">
            {{ form.contacts_mobile }}
          </el-form-item>
          <el-form-item label="邮箱">
            {{ form.email }}
          </el-form-item>
          <el-form-item v-if="form.province" label="地址">
            <span v-if="form.province">{{ `${form.province}${form.city}${form.area}` }}</span>
            <span v-if="form.detail">{{ form.detail }}</span>
          </el-form-item>
          <el-form-item v-if="form.id_card_front || form.id_card_reverse" label="身份证照片">
            <!-- <div v-if="form.id_card_front" class="img" :style="`background-image:url(${form.id_card_front});`"></div>
            <div v-if="form.id_card_reverse" class="img" :style="`background-image:url(${form.id_card_reverse});`"></div> -->
            <viewer :images="[form.id_card_front, form.id_card_reverse]">
              <template slot-scope="scope">
                <img v-for="(src, i) in scope.images" :key="i" class="img" :src="src" alt="">
              </template>
            </viewer>
          </el-form-item>
        </div>
      </el-form>
    </div>

    <div v-if="step === 3" class="result">
      <img src="https://mktv-in-cdn.mockuai.com/16171611545307823.png" alt="success" class="icon">
      <div class="title">投诉材料已经提交</div>
      <div class="desc">我们将在3个工作日内审核完毕并反馈</div>
      <div class="btn" @click="gotoRecord">查看投诉记录</div>
    </div>

    <div v-if="step === 1 || step === 2" class="actions">
      <div v-if="step === 1" class="btn btn-only" @click="next">下一步</div>
      <div v-if="step === 2" class="btn btn-primary" @click="back">返回修改</div>
      <div v-if="step === 2" class="btn" @click="submit">确认无误，提交</div>
    </div>
  </div>
</template>

<script>
import UploadItems from '@/components/UploadItems.vue';
import FileUpload from '@/components/FileUpload.vue';
import VDistpicker from 'v-distpicker'
import Viewer from 'v-viewer/src/component.vue'
import util from '@/js/util';

const radioData = [
  {
    id: 1,
    text: '商标权（包括发布销售假冒商品及其他未经授权或超出授权范围在所发布的商品信息中使用他人注册商标行为。）',
    neededMaterials: [
      {
        label: '商标注册证',
        key: 'item_registration_certificate',
        example: 'https://mktv-in-cdn.mockuai.com/16182320962493829.png'
      },
      {
        label: '品牌授权书',
        key: 'item_authorization',
        example: 'https://mktv-in-cdn.mockuai.com/16182320887032731.png'
      },
      {
        label: '商标转让证明',
        key: 'trademark_transfer_certificate',
        example: 'https://mktv-in-cdn.mockuai.com/16182321067006025.jpg'
      }
    ]
  },
  {
    id: 2,
    text: '著作权（包含但不限于图书、电子书、软件等，在平台发布、销售及其他侵害他人著作权行为）',
    neededMaterials: [
      {
        label: '作品登记证书',
        key: 'work_registration_certificate',
        example: 'https://mktv-in-cdn.mockuai.com/16182321118119070.png'
      },
      {
        label: '商品原图',
        key: 'item_original_picture'
      },
      {
        label: '商品花絮图',
        key: 'item_sidelights'
      }
    ]
  },
  {
    id: 3,
    text: '专利权（包含侵害他人外观设计专利、实用新型专利或发明专利。 ）',
    neededMaterials: [
      {
        label: '专利证书',
        key: 'patent_certificate',
        example: 'https://mktv-in-cdn.mockuai.com/16182321014089364.png'
      },
      {
        label: '专利权利要求与说明书',
        key: 'patent_specification'
      },
      {
        label: '专利缴费证明',
        key: 'patent_payment_certificate'
      }
    ]
  },
  {
    id: 4,
    text: '其他依法违规投诉（盗用营业执照、品牌授权等行为）',
    neededMaterials: [
      {
        label: '营业执照证明',
        key: 'business_license_certificate'
      }
    ]
  }
]

const materialItems = radioData.reduce((prev, cur) => {
  return prev.concat(cur.neededMaterials.map(item => ({...item, showType: cur.id})));
}, []);

const mobileCheck = (rule, value, callback) => {
  // if (!value) {
  //   return callback(new Error('请输入联系人电话'))
  // }
  if (!util.isPhoneAvailable(value)) {
    return callback(new Error('请输入正确的手机号'))
  }
  callback();
}

export default {
  name: 'IntellectualComplaint',
  components: {
    UploadItems,
    FileUpload,
    VDistpicker,
    Viewer
  },
  data() {
    return {
      step: 1,
      roleRadioData: [
        {
          id: 0,
          text: '个人'
        },
        {
          id: 1,
          text: '单位'
        }
      ],
      placeholders: {
        province: '选择省',
        city: '选择市',
        area: '选择区'
      },
      radioData,
      items: materialItems,
      form: {
        complaint_type: 1,
        subject: 0,
        contacts_name: '',
        contacts_mobile: '',
        email: '',
        business_name: '',
        province: '',
        city: '',
        area: '',
        detail: '',
        id_card_front: '',
        id_card_reverse: '',
      },
      rules: {
        complaint_type: [
          { required: true, message: '请选择投诉类型', trigger: 'blur' }
        ],
        subject: [
          { required: true, message: '请选择投诉主体', trigger: 'blur' }
        ],
        business_name: [
          { required: true, message: '请输入企业名称', trigger: 'blur' }
        ],
        contacts_name: [
          { required: true, message: '请输入联系人姓名', trigger: 'blur' }
        ],
        contacts_mobile: [
          { required: true, message: '请输入联系人电话', trigger: 'blur' },
          { validator: mobileCheck, trigger: 'blur' }
        ],
        email: [
          { required: true, message: '请输入邮箱', trigger: 'blur' }
        ],
        id_card_front: [
          { required: true, message: '请上传身份证正面' }
        ],
        id_card_reverse: [
          { required: true, message: '请上传身份证反面' }
        ]
      },
      uploadParams: {},
    }
  },
  computed: {
    currentRole() {
      return this.roleRadioData.find(item => item.id === this.form.subject).text;
    },
    currentItems() {
      return this.radioData.find(item => item.id === this.form.complaint_type).neededMaterials;
    },
    complainText() {
      return this.radioData.find(item => item.id === this.form.complaint_type).text;
    }
  },
  methods: {
    onTypeChange(val) {
      console.log(val);
    },

    onUploadsChange(data) {
      this.uploadParams = data;
    },

    onUploadSuccess(src, key) {
      this.form[key] = src;
    },

    onUploadError(e) {
      console.log(e);
      this.$message.error('文件上传失败')
    },

    onUploadDelete(e, key) {
      this.form[key] = ''
    },

    onAreaSelected(data) {
      console.log(data)
      const { province, city, area } = data;
      this.form.province = province.value;
      this.form.city = city.value;
      this.form.area = area.value;
    },

    gotoRecord() {
      this.$router.push('record');
    },

    onSubjectChange() {
      let keys = [
        'business_name',
        'contacts_name',
        'contacts_mobile',
        'email',
        'province',
        'city',
        'area',
        'detail',
        'id_card_front',
        'id_card_reverse'
      ]
      keys.map(key => {
        this.form[key] = '';
      })
      this.$refs.frontUpload.clearFiles()
      this.$refs.reverseUpload.clearFiles()
      this.$refs.frontFormItem.clearValidate()
      this.$refs.reverseFormItem.clearValidate()
      this.$refs.mobileFormItem.clearValidate()
      this.$refs.nameFormItem.clearValidate()
      this.$refs.emailFormItem.clearValidate()
    },

    next() {
      this.$refs.form.validate((res) => {
        if (res) {
          if (!this.form.province || !this.form.city || !this.form.area) {
            return this.$message.error('请选择完整的省市区')
          }
          if (!this.form.detail) {
            return this.$message.error('请输入详细地址')
          }
          const valueArr = Object.keys(this.uploadParams).map(key => this.uploadParams[key])
          const notPass = !valueArr.length || valueArr.some(item => !item);
          if (notPass) {
            return this.$message.error('请上传完整的举证材料');
          } else {
            this.step = 2;
          }
        }
      })
      
    },

    back() {
      this.step = 1;
    },

    submit() {
      window.lib.api.post({
        api: 'ec/c/workorder/right/complaint/add',
        data: {
          ...this.form,
          ...this.uploadParams,
        },
        success: () => {
          this.step = 3;
        },
        error: err => {
          this.$message.error(err.msg);
        }
      })
    },

    initViewer(viewer, key) {
      this[`${key}Viewer`] = viewer
    },

    showViewer(key) {
      this[`${key}Viewer`].show()
    }
  }
}
</script>

<style lang="less" scoped>
  .complaint-wrapper {
    padding-top: 60px;
    padding-bottom: 100px;
    .step-box {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .step-item {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 318px;
        height: 60px;
        color: #333;
        font-size: 22px;
        background-image: url(https://mktv-in-cdn.mockuai.com/16170121647535029.png);
        background-size: cover;
        &.active {
          color: #fff;
          background-image: url(https://mktv-in-cdn.mockuai.com/16170121942916286.png);
        }
        &:first-child {
          color: #fff;
          left: 0;
          background-image: url(https://mktv-in-cdn.mockuai.com/16170121149065589.png);
        }
        &:nth-child(2) {
          left: -16px;
        }
        &:nth-child(3) {
          left: -32px;
        }
      }
    }
    .explain {
      margin-bottom: 60px;
      padding: 40px 20px;
      background-color: #f7f8fb;
      border-radius: 4px;
      h3 {
        margin-bottom: 16px;
        line-height: 33px;
        font-weight: 500;
        font-size: 24px;
        color: #333;
      }
      ul li {
        line-height: 30px;
        font-weight: 400;
        font-size: 18px;
        color: #333;
      }
    }
    .actions {
      position: fixed;
      left: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 84px;
      background-color: #fff;
      border-top: 1px solid #eee;
      .btn {
        height: 44px;
        line-height: 44px;
        margin-right: 16px;
        padding: 0 45px;
        text-align: center;
        background-color: #ff6000;
        border-radius: 2px;
        color: #fff;
        font-weight: 500;
        font-size: 16px;
        cursor: pointer;
        &:last-child {
          margin-right: 0;
        }
        &:hover {
          background: rgba(255, 96, 0, 0.85);
        }
        &.btn-primary {
          border: 1px solid #dedede;
          background-color: #fff;
          color: #333;
          font-weight: 400;
          &:hover {
            border: 1px solid #ff6000;
            color: #ff6000;
          }
        }
        &.btn-only {
          width: 300px;
        }
      }
    }
    .form {
      .form-area {
        margin-bottom: 60px;
        &.center {
          /deep/ .el-form-item {
            display: flex;
            align-items: center;
            .el-form-item__content {
              margin-left: 0!important;
            }
          }
        }
        &.upload {
          .el-radio-group {
            margin-top: 10px;
          }
        }
      }
      .form-title {
        margin-bottom: 30px;
        line-height: 25px;
        color: #333;
        font-size: 18px;
      }
      .tips {
        margin-bottom: 30px;
        font-weight: 400;
        font-size: 15px;
        color: #999;
      }
      /deep/ .el-form-item__label {
        padding-right: 18px;
        font-size: 16px;
        color: #333;
      }
      /deep/ .el-radio {
        .el-radio__input {
          &.is-checked {
            .el-radio__inner {
              border-color: #ff6000;
              background-color: #ff6000;
            }
          }
        }
        .el-radio__label {
          white-space: initial;
          line-height: 22px;
          font-weight: 400;
          font-size: 16px;
          color: #333;
        }
        &.radio-block {
          display: flex;
          margin-bottom: 15px;
          &:last-child {
            margin-bottom: 0;
          }
          .el-radio__input {
            position: relative;
            top: 4px;
          }
          
        } 
        
      }
      .normal-input {
        width: 300px;
      }
      .distpicker-address-wrapper {
        /deep/ label > select {
          // color: #999;
          font-size: 14px;
        }
      }
      .address-textarea {
        margin-top: 20px;
        /deep/ .el-textarea__inner {
          width: 300px;
          height: 119px;
        }
      }
      &.confirm-info {
        /deep/  .el-form-item__content {
          font-size: 16px;
          color: #333;
        }
        .img {
          display: inline-block;
          margin-right: 30px;
          width: 90px;
          height: 90px;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          object-fit: scale-down;
          cursor: pointer;
        }
      }
    }
    .result {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 116px;
      .icon {
        width: 80px;
        height: 80px;
        margin-bottom: 40px;
      }
      .title {
        margin-bottom: 24px;
        color: #333;
        font-weight: 400;
        font-size: 24px;
      }
      .desc {
        margin-bottom: 50px;
        line-height: 20px;
        color: #999;
        font-weight: 400;
        font-size: 18px;
      }
      .btn {
        height: 44px;
        line-height: 44px;
        padding: 0 55px;
        background-color: #fff;
        border: 1px solid #dedede;
        border-radius: 2px;
        text-align: center;
        color: #333;
        font-weight: 400;
        font-size: 16px;
        cursor: pointer;
      }
    }
    .mb-10 {
      margin-bottom: 10px!important;
    }
  }
</style>
